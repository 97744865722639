import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch, useLocation } from "react-router-dom";

import Nps from "./pages/Nps";
import NotFound from "./pages/NotFound";
import api from "./services/api";

function App() {

  const pathname = window.location.pathname;
  const npsUuid = pathname.split('/')[2];
  const [customerLogo, setCustomerLogo] = useState('');

  useEffect( () => {
    async function getCustomer(){
      await api.get(`customer/${npsUuid}`)
      .then((response) => {
        setCustomerLogo(response.data.client.logo);
      })
    }
    getCustomer();
  }, [])

  return (
    <>
      <div className="App">
        <div className="container pb-5">
          <div className="row">
            <div className="col-md-12 text-center">
              <img
                className="customer-logo"
                src={
                  process.env.REACT_APP_API_URL + '/storage/' +customerLogo
                }
                alt="CX Solution"
                width="100px"
              />
            </div>
          </div>
          <Router>
            <Switch>
              <Route path="/:client/:nps">
                <Nps />
              </Route>
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </Router>
        </div>
      </div>
    </>
  );
}

export default App;
