import React, { useContext } from "react";
import Slider from "react-input-slider";

import "./style.css";

export default function ContentNps({ratingContext, mainQuestion}) {
  const [score, setScore] = useContext(ratingContext)

  const npsStyle = {
    backgroundColor: process.env.REACT_APP_COMPANY_COLOR,
  };

  return (
    <div
      id="nps"
      className="col-md-12 col-lg-6 pt-20-sm"
      style={{
        backgroundColor: process.env.REACT_APP_COMPANY_COLOR
          ? process.env.REACT_APP_COMPANY_COLOR
          : "#515151",
      }}
    >
      <div className="text-center">
        <h1 className="text-shadow">Avalie</h1>
        <div className="nps-info mt-4">
          <span className="text-shadow">
            {mainQuestion
              ? mainQuestion
              : "De 0 a 10, quanto você recomendaria a Verticis para um amigo ou empresa parceira?"}
          </span>
        </div>
      </div>
      <div className="mt-5">
        <div className="big-number text-center">
          <span className="text-shadow">{score}</span>
        </div>
        <div className="nps-range mt-5">
          <a
            href="#"
            className="nps-links"
            style={{ backgroundColor: "#b72025" }}
            onClick={(e)=>{e.preventDefault(); setScore(0)}}
          >
            0
          </a>
          <a
            href="#"
            className="nps-links"
            style={{ backgroundColor: "#d62027" }}
            onClick={(e)=>{e.preventDefault(); setScore(1)}}
          >
            1
          </a>
          <a
            href="#"
            className="nps-links"
            style={{ backgroundColor: "#f05223" }}
            onClick={(e)=>{e.preventDefault(); setScore(2)}}
          >
            2
          </a>
          <a
            href="#"
            className="nps-links"
            style={{ backgroundColor: "#f36f21" }}
            onClick={(e)=>{e.preventDefault(); setScore(3)}}
          >
            3
          </a>
          <a
            href="#"
            className="nps-links"
            style={{ backgroundColor: "#faa823" }}
            onClick={(e)=>{e.preventDefault(); setScore(4)}}
          >
            4
          </a>
          <a
            href="#"
            className="nps-links"
            style={{ backgroundColor: "#ffca27" }}
            onClick={(e)=>{e.preventDefault(); setScore(5)}}
          >
            5
          </a>
          <a
            href="#"
            className="nps-links"
            style={{ backgroundColor: "#ecdb12" }}
            onClick={(e)=>{e.preventDefault(); setScore(6)}}
          >
            6
          </a>
          <a
            href="#"
            className="nps-links"
            style={{ backgroundColor: "#e8e73d" }}
            onClick={(e)=>{e.preventDefault(); setScore(7)}}
          >
            7
          </a>
          <a
            href="#"
            className="nps-links"
            style={{ backgroundColor: "#c5d92d" }}
            onClick={(e)=>{e.preventDefault(); setScore(8)}}
          >
            8
          </a>
          <a
            href="#"
            className="nps-links"
            style={{ backgroundColor: "#afd136" }}
            onClick={(e)=>{e.preventDefault(); setScore(9)}}
          >
            9
          </a>
          <a
            href="#"
            className="nps-links"
            style={{ backgroundColor: "#64b64d" }}
            onClick={(e)=>{e.preventDefault(); setScore(10)}}
          >
            10
          </a>
          {/* <Slider
                styles={{
                  track: {
                    backgroundColor: 'rgba(255, 255, 255, .5)',
                    height: 10,
                    width: '100%'
                  },
                  active: {
                    backgroundColor: '#fff'
                  },
                  thumb: {
                    width: 30,
                    height: 30,
                    border: '3px solid #fff',
                    backgroundColor: process.env.REACT_APP_COMPANY_COLOR ? process.env.REACT_APP_COMPANY_COLOR : '#adca40'
                  }
                }}
                xmin={0}
                xmax={10}
                x={score}
                onChange={({ x }) => setScore(x)}
              /> */}
        </div>
      </div>
    </div>
  );
}
